import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

const SUBGRAPH_BASE_URL = 'http://20.108.176.236:8000/subgraphs/'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `${SUBGRAPH_BASE_URL}/exchange`
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const governanceClient = new ApolloClient({
  link: new HttpLink({
    uri: `http://127.0.0.1:8000/subgraphs/name/pangolindex/dijets-governance`
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'http://20.108.176.236:8000/subgraphs/name/council/props'
  }),
  cache: new InMemoryCache()
})
