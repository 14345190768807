import styled from 'styled-components'
import { AutoColumn } from '../Column'

import uImage from '../../assets/images/big_unicorn.png'

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: fit-content;
  justify-self: flex-end;
`

export const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
  display: grid;
  grid-auto-rows: auto;
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
  /* background: radial-gradient(76.02% 75.41% at 1.84% 0%,#27a9ae 0%,#000000 100%); */
  overflow: hidden;
`

export const CardBGImage = styled.span<{ desaturate?: boolean }>`
  /* background: url(/static/media/big_unicorn.f296b62b.png); */
  /* width: 1000px; */
  /* height: 600px; */
  position: absolute;
  display: none;
  border-radius: 12px;
  opacity: 0.4;
  top: -100px;
  left: -100px;
  /* -webkit-transform: rotate(-15deg); */
  -ms-transform: rotate(-15deg);
  /* transform: rotate(-15deg); */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
  background: url(${uImage});
  width: 1200px;
  height: 1200px;
  display: none;
  position: absolute;
  border-radius: 12px;
  top: -300px;
  left: -300px;
  opacity: 0.4;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardNoise = styled.span`
  background: url(${uImage});
  background-size: cover;
  display: none;
  /* mix-blend-mode: overlay; */
  border-radius: 12px;
  width: 100%;
  height: 100%;
  transform: rotate(-15deg);
  opacity: 0.45;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`

export const Break = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
`
