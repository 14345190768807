export const IDO_STATUS_UPCOMING = 'Upcoming'
export const IDO_STATUS_ENDED = 'Ended'

interface AnnouncedIDOItem {
  id: number
  title: string
  description?: string
  status: string
  launchpad: string
  announcementUrl: string
  projectIconLocation: string
  projectUrl: string
  startTime?: string
  endTime?: string
}

export const IDO_LIST: Array<AnnouncedIDOItem> = [
  {
    id: 1,
    title: 'Daniel Fitzhenry',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  },
  {
    id: 2,
    title: 'Piers Hampton',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  },
  {
    id: 3,
    title: 'Alisha Ramazannezhad',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  },
  {
    id: 4,
    title: 'Peter Robertson',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  },
  {
    id: 5,
    title: 'Joe Fitzgerald',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  },
  {
    id: 6,
    title: 'Nikhil Prabharkar',
    description:
      'Ex Leader of Southampton Council, is the visibility lead for Dijets Governance and its Products and Services.',
    status: IDO_STATUS_ENDED,
    launchpad: 'Council Registry',
    announcementUrl: '#',
    projectIconLocation: 'https://voting.dijets.io/djtx-council.svg',
    projectUrl: '#',
    startTime: '10/10/2022',
    endTime: '11/12/2023'
  }
]
