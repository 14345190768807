// the Pangolin Default token list lives here
export const AEB_TOKENLIST = 'https://raw.githubusercontent.com/lasthyphen/dijets-tokens/master/tokenlists/tokens.json'
// eslint-disable-next-line @typescript-eslint/camelcase
export const TOP_15_TOKEN_List =
  'https://raw.githubusercontent.com/lasthyphen/dijets-tokens/master/tokenlists/tokens.json'
export const DEFI_TOKEN_LIST =
  'https://raw.githubusercontent.com/lasthyphen/dijets-tokens/master/tokenlists/tokens.json'
export const STABLECOIN_TOKEN_LIST =
  'https://raw.githubusercontent.com/lasthyphen/dijets-tokens/master/tokenlists/tokens.json'
export const DJTX_BRIDGE_LIST =
  'https://raw.githubusercontent.com/lasthyphen/dijets-tokens/master/tokenlists/tokens.json'
export const DEFAULT_TOKEN_LIST_URL = DJTX_BRIDGE_LIST

export const DEFAULT_LIST_OF_LISTS: string[] = [
  // eslint-disable-next-line @typescript-eslint/camelcase
  TOP_15_TOKEN_List,
  AEB_TOKENLIST,
  DEFAULT_TOKEN_LIST_URL,
  DEFI_TOKEN_LIST,
  STABLECOIN_TOKEN_LIST,
  DJTX_BRIDGE_LIST
]
