import { ChainId, TokenAmount, WDJTX, JSBI } from '@pangolindex/sdk'
import React, { useMemo } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import tokenLogo from '../../assets/images/token-logo.png'
import { FRD } from '../../constants'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'
import { STAKING_REWARDS_CURRENT_VERSION, useTotalFrdEarned } from '../../state/stake/hooks'
import { useAggregateFrdBalance, useTokenBalance } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE, FrdTokenAnimated } from '../../theme'
import { computeFrdCirculation } from '../../utils/computeFrdCirculation'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { Break, CardBGImage, CardNoise, CardSection, DataCard } from '../earn/styled'
import { usePair } from '../../data/Reserves'
import { useTranslation } from 'react-i18next'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const ModalUpper = styled(DataCard)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #f97316 0%, #e84142 100%);
  padding: 0.5rem;
`

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`

/**
 * Content for balance stats modal
 */
export default function FrdBalanceContent({ setShowFrdBalanceModal }: { setShowFrdBalanceModal: any }) {
  const { account, chainId } = useActiveWeb3React()
  const frd = chainId ? FRD[chainId] : undefined

  const total = useAggregateFrdBalance()
  const frdBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, frd)
  const frdToClaim: TokenAmount | undefined = useTotalFrdEarned()

  const totalSupply: TokenAmount | undefined = useTotalSupply(frd)

  // Determine FRD price in DJTX
  const wdjtx = WDJTX[chainId ? chainId : 43112]
  const [, djtxFrdTokenPair] = usePair(wdjtx, frd)
  const oneToken = JSBI.BigInt(1000000000000000000)
  const { t } = useTranslation()
  let frdPrice: number | undefined
  if (djtxFrdTokenPair && frd) {
    const djtxFrdRatio = JSBI.divide(
      JSBI.multiply(oneToken, djtxFrdTokenPair.reserveOf(wdjtx).raw),
      djtxFrdTokenPair.reserveOf(frd).raw
    )
    frdPrice = JSBI.toNumber(djtxFrdRatio) / 1000000000000000000
  }

  const blockTimestamp = useCurrentBlockTimestamp()
  const circulation: TokenAmount | undefined = useMemo(
    () =>
      blockTimestamp && frd && chainId === ChainId.FUJI ? computeFrdCirculation(frd, blockTimestamp) : totalSupply,
    [blockTimestamp, chainId, totalSupply, frd]
  )

  return (
    <ContentWrapper gap="lg">
      <ModalUpper>
        <CardBGImage />
        <CardNoise />
        <CardSection gap="md">
          <RowBetween>
            <TYPE.white color="white">{t('header.frdBreakDown')}</TYPE.white>
            <StyledClose stroke="white" onClick={() => setShowFrdBalanceModal(false)} />
          </RowBetween>
        </CardSection>
        <Break />
        {account && (
          <>
            <CardSection gap="sm">
              <AutoColumn gap="md" justify="center">
                <FrdTokenAnimated width="48px" src={tokenLogo} />{' '}
                <TYPE.white fontSize={48} fontWeight={600} color="white">
                  {total?.toFixed(2, { groupSeparator: ',' })}
                </TYPE.white>
              </AutoColumn>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white color="white">{t('header.balance')}</TYPE.white>
                  <TYPE.white color="white">{frdBalance?.toFixed(2, { groupSeparator: ',' })}</TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white color="white">{t('header.unclaimed')}</TYPE.white>
                  <TYPE.white color="white">
                    {frdToClaim?.toFixed(4, { groupSeparator: ',' })}{' '}
                    {frdToClaim && frdToClaim.greaterThan('0') && (
                      <StyledInternalLink
                        onClick={() => setShowFrdBalanceModal(false)}
                        to={`/frd/${STAKING_REWARDS_CURRENT_VERSION}`}
                      >
                        ({t('earn.claim')})
                      </StyledInternalLink>
                    )}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
            <Break />
          </>
        )}
        <CardSection gap="sm">
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.white color="white">{t('header.frdPrice')}</TYPE.white>
              <TYPE.white color="white">{frdPrice?.toFixed(5) ?? '-'} DJTX</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">{t('header.frdCirculation')}</TYPE.white>
              <TYPE.white color="white">{circulation?.toFixed(0, { groupSeparator: ',' })}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">{t('header.totalSupply')}</TYPE.white>
              <TYPE.white color="white">{totalSupply?.toFixed(0, { groupSeparator: ',' })}</TYPE.white>
            </RowBetween>
          </AutoColumn>
        </CardSection>
      </ModalUpper>
    </ContentWrapper>
  )
}
